import React, { Component } from 'react';
import "./SelectCompanyPage.css";
import SignUp from "./SignUp1.js";
import SelectCompany     from "./SelectCompany.js";
import SelectPackage     from "./SelectPackage.js";
import ConfirmOtp     from "./ConfirmOtp1.js";

class EmpSignUpPage extends Component {
constructor() {
    super();
    this.state = {
      showHide1: true,
      showHide2: false,
      showHide3: false,
      showHide4: false, 
    };
    this.hideComponent = this.hideComponent.bind(this);
}
 hideComponent(name) {
    console.log(name);
    switch (name) {
      case "showHide1":
        this.setState({ 
                    showHide1: true,
                    showHide2: false,
                    showHide3: false,
                    showHide4: false,
                });
        break;
      case "showHide2":
        this.setState({ 
                    showHide2: true ,
                    showHide1: false,
                    showHide3: false,
                    showHide4: false,
                });
        break;
      case "showHide3":
        this.setState({ 
                    showHide3: true,
                    showHide2: false,
                    showHide1: false,
                    showHide4: false,
                });
        break;
        case "showHide4":
        this.setState({ 
                    showHide4: true,
                    showHide2: false,
                    showHide1: false,
                    showHide3: false,
                });
        break;
      default:
      case "showHide":
         // case "showHide1":
       this.setState({ 
                    showHide1: true,
                    showHide2: false,
                    showHide3: false,
                    showHide4: false,
                });
        break;
    }
  } 
    
    // showFirst(){
    //     var blkNameFirst=document.getElementById("firstBlk");
    //     blkNameFirst.classList.add("active");

    //     var blkName=document.getElementById("secBlk");
    //     blkName.classList.remove("active");

    //     var thirdblkName=document.getElementById("thirdBlk");
    //     console.log("thirdblkName",thirdblkName)
    //     thirdblkName.classList.remove("active");
    //     this.setState({ 
    //         showFirst:true,
    //         showSec  : false,
    //         showThird:false,
    //         // showHide1: false,
    //         showHide2: false,
    //         // showHide3: false,
    //         // showHide4: false,
    //     });
    //   }
    //   showSecond(){
    //     var blkName=document.getElementById("secBlk");
    //     blkName.classList.add("active");

    //     var firstblk=document.getElementById("firstBlk");
    //     firstblk.classList.remove("active");

    //     var thirdblk=document.getElementById("thirdBlk");
    //     thirdblk.classList.remove("active");
    //     this.setState({ 
    //         showSec: true,
    //         showFirst:false,
    //         showThird:false,
    //         showHide1: false,
    //         showHide2: false,
    //         showHide3: false,
    //         showHide4: false,
    //     });
    // }
    //   showThird(){
    //     var blkName=document.getElementById("thirdBlk");
    //     blkName.classList.add("active");

    //     var firstblk=document.getElementById("firstBlk");
    //     firstblk.classList.remove("active");

    //     var secondblk=document.getElementById("secBlk");
    //     // console.log("secondblk",secondblk)
    //     secondblk.classList.remove("active");
    //     this.setState({ 
    //         showThird:true,
    //         showFirst:false,
    //         showSec: false,
    //         showHide1: false,
    //         showHide2: false,
    //         showHide3: false,
    //         showHide4: false,
    //     });
    //   }
    //   showFourth(){
    //     this.setState({ 
    //         showFirst:false,
    //         showSec: false,
    //         showThird:false,
    //         showFourth:true,
    //         showHide1: false,
    //         showHide2: false,
    //         showHide3: false,
    //         showHide4: false,
    //     });
    //   }
  
render() {
    const { showHide1, showHide2, showHide3 ,showHide4} = this.state;
    console.log("this.state",this.state)
    return (
        <section className="signUpFormMainWrapper">
            <div className={showHide4 ?"col-lg-8 col-lg-offset-2 signUpBoxWrapper signUpBoxWrapper1":"col-lg-4 col-lg-offset-4 signUpBoxWrapper "}>
                <div className="row">
                    <div className="ProgressBarSignUpWrapper">
                        <div className="row progressBar">
                            <div className="step col-lg-3" >
                                {/* <a href='/#'  > */}
                                    <div id="firstBlk" className={(showHide1) ||(showHide2)||(showHide3) ||(showHide4)

                                                    ?
                                                        "bullet active"
                                                    :
                                                        "bullet"
                                                    }>
                                        1
                                    </div>
                                {/* </a> */}
                            </div>
                            <div className="step col-lg-3" >
                                {/* <a href='/#' > */}
                                <div id="secBlk" className={(showHide2)||(showHide3) ||(showHide4)
                                                ?
                                                    "bullet active"
                                                :
                                                    "bullet"
                                                }>
                                    2
                                </div>
                                {/* </a> */}
                            </div>
                            <div className="step col-lg-3"  >
                                {/* <a href='/#'> */}
                                    <div  id="thirdBlk" className={(showHide3)||(showHide4)?"bullet active":"bullet"}>
                                        3
                                    </div>
                                {/* </a> */}
                            </div>
                            <div className="step col-lg-3" >
                                {/* <a  href='/#'> */}
                                    <div className={(showHide4)?"bullet active":"bullet"}>
                                        4
                                    </div>
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row"> 
                {console.log("showHide1",showHide1)}
                    {showHide1 && <SelectCompany hideComponent = {this.hideComponent.bind(this)} />}
                {/* {
                    this.state.showFirst===true?
                        <SelectCompany/>
                    :
                        null
                } */}
                </div>
                {console.log("showHide2",showHide2)}
                <div className="row"> 
                    {showHide2 && <SignUp hideComponent = {this.hideComponent.bind(this)} />}
                    {/* {
                        this.state.showSec ===true?
                        <SignUp/>
                        :
                        null
                    } */}
                </div>
                <div className="row"> 
                    {showHide3 && <ConfirmOtp hideComponent = {this.hideComponent.bind(this)} />}
                    {/* {
                        this.state.showThird ===true?
                        <ConfirmOtp/>
                        :
                        null
                    } */}
                </div>
                <div className="row"> 
                    {showHide4 && <SelectPackage hideComponent = {this.hideComponent.bind(this)} />}
                    {/* {
                        this.state.showFourth ===true?
                        <SelectPackage/>
                        :
                        null
                    } */}
                </div>
            </div>
        </section>
    );
}

}
export default EmpSignUpPage;